<template>

  <div id="checkout_view">
  
    <div v-if="!viewModal">
      <form  id="payment-form">
        <input type="hidden" name="token_id" id="token_id" />
        <input
          type="hidden"
          name="use_card_points"
          id="use_card_points"
          value="false"
        />
        <div class="pymnt-itm card active">
          
          <div class="pymnt-cntnt">
            <div class="card-expl dflx">
              <div class="box-card credit">
                  <h4>Tarjetas de <span>crédito</span></h4>
                  <img src="../../assets/TDC.svg" alt="Iconos tarjetas de crédito aceptadas.">
              </div>
              <div class="box-card debit">
                  <h4>Tarjetas de <span> débito</span></h4>
                   <img src="../../assets/TDD.svg" alt="Iconos tarjetas de débito aceptadas.">
                </div>
            </div>
            <div class="sctn-row">
              <div class="sctn-col grp1 l">
                <label>Nombre del titular</label
                ><input
                  v-model="name"
                  type="text"
                  placeholder="Como aparece en la tarjeta"
                  autocomplete="off"
                  data-openpay-card="holder_name"
                />
              </div>
              <div class="sctn-col grp1">
                <label>Número de tarjeta</label
                ><input
                  v-model="tarjeta"
                  type="text"
                  autocomplete="off"
                  data-openpay-card="card_number"
                  placeholder="4242424242424242"
                  
                />
              </div>
            </div>
            <div class="sctn-row  dflx">
              <div class="sctn-col grp2  l">
                <label>Fecha de expiración</label>
                <div class="dflx">
                   <div class="sctn-col half l">
                  <input
                  v-model="expmonth"
                   class="inp1"
                    type="text"
                    placeholder="MM"
                    data-openpay-card="expiration_month"
                  />
                </div>
                <div class="sctn-col half l">
                  <input
                  v-model="expyear"
                  class="inp2"
                    type="text"
                    placeholder="YY"
                    data-openpay-card="expiration_year"
                  />
                </div>
                </div>
              </div>
              <div class="sctn-col grp3 cvv">
                <label>CVV</label>
                <div class="sctn-col half l">
                  <input
                  v-model="cvv"
                    type="text"
                  
                    autocomplete="off"
                    data-openpay-card="cvv2"
                  />
                </div>
              </div>
            </div>
            <div class="openpay">
              <div class="logoop dflx">
                <p>Transacciones realizadas vía:</p>
                <img src="../../assets/openpaylogo.svg" alt="Icono openpay">
              </div>
              <div class="shield dflx">
                 <img src="../../assets/security.svg" alt="Icono escudo.">
                <p>Tus pagos se realizan de forma segura con encriptación de 256
                bits</p>
               
              </div>
            </div>
            <div class="sctn-row">
             <a v-if="wait==false" class="button rght" id="pay-button">Pagar</a> 
               <p  v-if="wait" class="button rght" id="pay-button2"><img src="../../assets/Cargando0.gif" alt="cargando gif"></p>
            </div>

            <p id="error_msg" v-if="status=='error'" >
              {{message}}
            </p>
          </div>
        </div>
      </form>
    </div>

        <div v-else class="view-error">
            <div class="ve-cnt">
             <!--    <img class="" src="" alt="icono app"> icono app -->
                <div class="ve-body">
                <!--    <img src="">  icono triste -->
                    <p v-if="messageback ==''" class="error_msg">LO SENTIMOS HA OCURRIDO UN ERROR AL INTENTAR REALIZAR TU PAGO, INTENTALO DE NUEVO.</p>
                    <p v-else class="error_msg">LO SENTIMOS EL MAXIMO DE COMPRA DEBE SER MENOR A $10,000 MXN.</p>
                </div>
                <a class="a_error" v-on:click=" refre()"><p class=""><span>ACEPTAR</span></p></a>
            </div>
        </div>
  </div> 
</template>
<script>

import {mapActions} from 'vuex';

export default {
    name:'checkout'
    ,
    data(){
      return{
        status:'',
        message:'',
        messageback:'',
        name:'',
        tarjeta:'',
        expmonth:'',
        expyear:'',
        cvv:'',
        wait:false,
        viewModal: false,
      }
    },
    /*
     4242424242424242
    */
   created(){
    window.fbq('track', 'AddPaymentInfo');
   },
    mounted() {
       
         $(document).ready(function() {

            OpenPay.setId('ml4fqdvdcj6z1k1wdpls');
            OpenPay.setApiKey('pk_438f88ae406d466da0e6b96ff1d5f6fd');
            OpenPay.setSandboxMode(false);
           /* OpenPay.setId('mkhenbwisncq2r3emnju');
            OpenPay.setApiKey('pk_9e1986293eb14fb69758a7e1b9f082a0');
            OpenPay.setSandboxMode(true); */
            //Se genera el id de dispositivo
            var deviceSessionId = OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
            
            $('#pay-button').on('click', function(event) {
                event.preventDefault();
                $("#pay-button").prop( "disabled", true);
                OpenPay.token.extractFormAndCreate('payment-form', sucess_callbak, error_callbak);                
            });

            var sucess_callbak = function(response) {
            
              var token_id = response.data.id;
              $('#token_id').val(token_id);
             // $('#payment-form').submit();
             //ENVIAR a otra pagina.
              let isval = execute()
             
              if(isval.status=='success'){
                 charge(deviceSessionId,token_id)
              }else{
                 showMessage(isval.message)
              }
              
             
             //console.log(token_id)
             //console.log(deviceSessionId)
     
            };

            var error_callbak = function(response) {
              
                var desc = response.data.description != undefined ? response.data.description : response.message;
                let message =  desc; //"ERROR [" + response.status + "] : " +
               showMessage(message)
              //console.log(message)
               $("#pay-button").prop("disabled", false);
            };

        });

        let showMessage =(error)=>{
           this.status ='error'
           this.message = error
           this.delStatus()
        } 

        let  charge =(deviceSessionId,token_id)=>{
            this.wait = true
            this.createCharge(deviceSessionId,token_id)
        }

        let execute= () => {
         return   this.validar( this.name, this.tarjeta, this.expmonth, this.expyear, this.cvv)
        }
    },
    watch:{
        cvv: function(value){
        
          let arr = value.split('')
       
          let str =''
          let count =0;
          for (const ctr of arr) {
              
              if(!isNaN(ctr) && count<4 && ctr !=' '){
                str += ctr
                count++
              } 
          }

          this.cvv= str
          
        },

        expmonth: function(value){
        
          let arr = value.split('')
       
          let str =''
          let count =0;
          for (const ctr of arr) {
             
            
              if(!isNaN(ctr) && count<2 && ctr !=' '){
                let newstr = str + ctr
               newstr = parseInt(newstr)
               if(newstr <=12){
                str += ctr
                count++
               }

              } 
          }

          this.expmonth= str
          
        },

         expyear: function(value){
        
          let arr = value.split('')
       
          let str =''
          let count =0;
          for (const ctr of arr) {
     
              if(!isNaN(ctr) && count<2 && ctr !=' '){
                str += ctr
                count++
              } 
          }

          this.expyear= str
          
        },
        tarjeta: function(value){
        
          let arr = value.split('')
       
          let str =''
          let count =0;
         
          for (const ctr of arr) {
     
              if(!isNaN(ctr) && count<16  && ctr !=' '){
                str += ctr
                count++
              } 
          }

          this.tarjeta= str
          
        }
    },
    computed:{
         payment(){
                return this.$store.getters["pasarela/getAdded"]
         },
    },
    methods:{
          ...mapActions('pasarela', ['setHistoryOptionPsl']),
     ...mapActions("carrito", ["setCart"]),
        ...mapActions('pasarela', ['setAddedPsl']),
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,5000);
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },

         refre: function(){
            setTimeout(() => this.$router.go(), 200);
            this.setHistoryOptionPsl('Default')
        },
        createCharge: async function(device, source){  
       
          this.messageback =''
           this.setHistoryOptionPsl('createCharge')
            let infopayment = this.payment

           let data = {
            "pedido":infopayment.pedido,
           "facturacion": infopayment.facturacion,
           "registrarUsuario": infopayment.registrarUsuario,
           "cart": infopayment.cart,
           "source_id":source,
           "device_session_id":device
        }


                let result = await this.$store.dispatch("purchase/createPurchase2", data);

              //console.log(result)

             
              if(result.status !='success'){
               
                    this.viewModal=true
                    if(result.message =='El total debe ser menor a $10,000.'){
                      this.messageback = result.message 
                    }
                    
              }else{
                 //si todo es correcto.
                 this.wait=false
                 this.setAddedPsl(result.result)
                 this.setHistoryOptionPsl('Finalizando')
                   
                       window.fbq('track', 'Purchase', {value: 0.00, currency: 'MXN'});
                    
                
                  let  COMPLETED = JSON.stringify(infopayment)
                  localStorage.setItem('COMPLETED',COMPLETED)
                  localStorage.removeItem('kth')

                  const url = result.receipt.payment_method.url

                  //this.$router.push("/detalle-compra").catch(err => {});
                  window.location.href = url
              }
              
          
            
            
            
        },

         toThankYou: async function(){
          return  this.$router.push("/finalizado").catch(err => {});
    },
        validar(name, tarjeta, expmonth, expyear, cvv){
          let srt =''
          let count =0
           if(name==''){
              srt +=' El nombre es requerido.'
              count++
           }

           if(tarjeta==''){
              srt +=' La tarjeta es requerida.'
              count++
           }

           if(expmonth==''){
              srt +=' El mes es requerido.'
              count++
           }

           if(expyear==''){
              srt +=' El año es requerido. '
              count++
           }

           if(cvv==''){
              srt +=' El cvv es requerido. '
              count++
           }

           if(count >0){
           // this.status='error'
           // this.message =srt
            return {
              status:'error',
              message: srt
            }
            }else{
                  return {
               status:'success',
              message: ''
               }
            }
        }

    }

};
</script>
<style scoped>
#checkout_view{
    
    width: 85%;
    margin: auto;
   /*margin-top: 5.384615384615385VW;*/
}


.box-card:first-child{
         width:48%;
    border-right: 0.5px solid #bebebe;
   
}.box-card:last-child{
       
          padding-left: 1.435897VW;
}

.box-card{
  margin-bottom: 3.358974VW;
}
.card{
  border: none;
}
.card-expl h4{
 font-family: 'Lato';
    font-size: 1VW;
    color: var(--color-2);
    font-weight: 500;
    margin: 0vw;
    margin-bottom: 0.582051VW;
}.card-expl h4 span{
  font-weight: 700;
}

.grp1{
  margin-bottom: 0.530769VW;
}
.grp1 label{
  display: grid;
}

#payment-form label{
     font-size: 1VW;
    color: var(--color-2);
    font-weight: 700;
    font-family: 'Lato';
    margin-bottom: 0.51282VW;
    padding-left: 1vw;
}

.grp1 input{
    width: 17.917vw;
    height: 1.719vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.625vw;
    line-height: 0.781vw;
    color: var(--color-5);
    border: 1px solid gainsboro;
    margin-bottom: 0.469vw;
}
#payment-form input::placeholder{
  color: var(--color-5);
   font-style: italic;
   font-family: 'Lato';
     font-size: 0.625vw;
   font-weight: 400;
}



.grp3{
  margin-left: 1.2999999999999994VW;
}

.grp2 input{

    width: 17.917vw;
    height: 1.719vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.625vw;
    line-height: 0.781vw;
    color: var(--color-5);
    border: 1px solid gainsboro;
    margin-bottom: 0.469vw;
}



.grp3 input{
    width: 5.7vw;
    height: 1.719vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.625vw;
    line-height: 0.781vw;
    color: var(--color-5);
    border: 1px solid gainsboro;
    margin-bottom: 0.469vw;
}

p.error_msg{
  border: 1px solid  var(--color-1);;
  color: var(--color-1);
  margin-top: 10%;
  padding: .5vw;
  font-size: .7vw;
}

a.a_error p{
  background: var(--color-2);
  padding: 1vw;
  margin: 0vw;
  cursor: pointer;
  color: white;
  text-transform: none;
  text-align: center !important;
}
a.a_error p:hover{

  text-transform: none !important;
}

input.inp1{
    width: 4.5vw;
    margin-right: .5vw;
}
input.inp2{
     width: 4.5vw;
} 

.openpay{
      width: 18.6vw;
  border-bottom: 0.5px solid #bebebe;
  border-top: 0.5px solid #bebebe;
margin-top: 1.351282VW;
    margin-bottom: 2.351282VW;
     padding-top: 0.58974VW;
    padding-bottom: 0.58974VW;
}

.logoop p{
       font-size: .8VW;
    color: rgba(134, 134, 134, 1);
    font-weight: 600;
    margin-top: 1vw;
}

.sctn-row{
  width: 100%;
}
.logoop img{
    width: 6.717949VW;
    height: 3.205128VW;
    margin-left: 0vw;
    padding-top: 0vw;
}

.shield p{
      color: rgba(134, 134, 134, 1);
    font-size: .7VW;
    margin-left: 0.5vw;
    width: 16vw;
    margin-top: 0vw;
}

.shield img{
 width: 1.948718VW;
    height: 1.487179VW;
    padding-top: .3vw;
  
}

a#pay-button{
     background: var(--color-1);
    color: rgba(255, 255, 255, 1);
    

        font-family: "Intro Rust";
    font-style: normal;
    font-weight: 400;
    font-size: 0.833vw;
    line-height: 2.031vw;
    color: #ffffff;

   padding: 1.5vw 7vw;
  cursor: pointer;
    text-align: center;
    text-transform: uppercase;
}


p#pay-button2{
  background: var(--color-1);
    color: rgba(255, 255, 255, 1);
    font-size: 3.076923076923077VW;
    font-weight: 800;
    /* padding: 1vw 1vw; */
    /* margin-left: 25%; */
    text-align: center;
    padding: 0vw;
    margin: 0vw;
}
p#pay-button2 img{
       width: 5vw;
    height: 1.5vw;
    padding-top: 0vw;
}

.box-card img{
     width: 6.974359VW;
    height: 3.076923VW;
        padding-left: 0.5vw;
}

#error_msg{
    background: var(--color-7);
    color: rgba(255, 255, 255, 1);
    font-size: .76923VW;
    padding: 0.5vw;
    text-align: center;
    width: 90.4%;
    margin-top: 1.5vw;
  
   
}


/** modal error */
.ModalOut {
    width: 67.6923076923077VW;
    height: 97.94871794871794VW;
    background-color: #FFFFFF;
    border-radius: 7.6923076923076925VW;
    position: absolute !important;
    /*margin-left: 16vw;
    margin-top: 59vw;*/
}
.MContent2 {
    width: 51.7948717948718VW;
    height: 40.897436VW;
    border: 0.6vw solid #F54C49;
    border-radius: 7.6923076923076925VW;
    text-align: center;
    margin-left: 7vw;
}
.MContent2 img {
    width: 11.025641025641026VW;
    height: 11.025641025641026VW;
    margin-top: 4.615384615384616VW;
}
.MContent2 p {
    width: 41vw;
    margin-inline: auto;
    font-size: 3.076923076923077VW;
    color: #215675;
    line-height: 3.6923076923076925VW;
    font-weight: 600;
}
p.GH {
    font-size: 3.5897435897435894VW;
    color: #215675;
    font-weight: 500;
    text-align: center;
    margin-top: 8.461538461538462VW;
}
.GH span {
    font-weight: 800;
}

@media (max-width: 768px) {
  .card-expl h4 {
     padding-left: 2vw;
        font-size: 3.6VW;
  
    margin: 0vw;
    margin-bottom: 0.582051VW;
}
.box-card.credit{
  padding-right: 1.5vw;
}

.box-card img {
    width: 32.974359VW;
    height: 18.076923VW;
    /* padding-left: 0.5vw; */
}

#payment-form label {
    font-size: 4VW;
}

.grp1 input {
    width: 100%;
    height: 9.719vw;
    
    font-size: 3.625vw;
   
}

#payment-form input::placeholder{

  font-size: 3.625vw;
}

input.inp1 {
    width: 18.5vw;
    margin-right: 0.5vw;

}
input.inp2 {
  
    width: 18.5vw;
}

.grp2 input , .grp3 input{
    height: 9.719vw;
    font-size: 3.625vw;
}

.grp3 input {
    width: 27.7vw;
}

.openpay {
      width: 100%;
    border-bottom: 0.5px solid #bebebe;
    border-top: 0.5px solid #bebebe;
    margin-top: 7.351282VW;
    margin-bottom: 11.351282VW;
    padding-top: 1.58974VW;
    padding-bottom: 1.58974VW;
}

.logoop p {
    font-size: 3VW;
    color: rgba(134, 134, 134, 1);
    font-weight: 600;
    margin-top: 2vw;
}

.logoop img {
    width: 20.717949VW;
    height: 9.205128VW;
    margin-left: 3vw;
    padding-top: 0vw;
}

.shield p {
    color: rgba(134, 134, 134, 1);
    font-size: 2.7VW;
    margin-left: 0.5vw;
    width: 90%;
    margin-top: 0vw;
}

.shield img {
    width: 9.948718VW;
    height: 9.487179VW;
     padding-top: 0vw;
}

a#pay-button {
   font-size: 3.833vw;
    padding: 7% 40%;
}


p#pay-button2{
  padding: 5.5vw 28.3vw;
}
p#pay-button2 img{
         width: 18vw;
    padding-top: 0vw;
    height: 4vw;
}


  
   
#error_msg{
 
    font-size: 2.76923VW;
    padding: 0.5vw;
 
    width: 99%;
    margin-top: 6.5vw;
}


}
</style>