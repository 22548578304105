<template>
    <div id="sec_shipment_details">
      <HeaderHome :name="user.fullname" />
      <AnuncioBar />
      <div class="Content_Detalles">
        <div class="Div_Titulo">
          <h1>Detalles de <span>envío</span></h1>
        </div>
        
        <!--<div class="Div_SubTitulo">
          <p>¿Tienes un cupón? <span class="in_cupon" @click="introducirCupon()">Haz click aquí para introducir tu código</span></p>
        </div>-->
        

          <form  @submit.prevent="
                submit(
                    nombre,
                    apellidos,
                    empresa,
                    pais_region,
                    direccion,
                    direccion2,
                    localidad_ciudad,
                    region,
                    cp_loc,
                    telefono,
                    email,
                    cuenta,
                    password,
                    password2,
                    facturar,
                    razon_social,
                    rfc,
                    direccion_fiscal,
                    cp_fac,
                    email_fac,
                    notas,
                    pago_con, 
                    terminos
                )
              ">
            <div class="cols_shdls">
            <div class="cl1_shdls">

              <div class="form_detalles_envio">

                <div class="mtrow df_group_frm">
                    <div class="group_frm">
                      <label class="Labels_Formulario" for="nombreDE">NOMBRE</label>
                      <input :disabled="loaded" class="Inputs_dobles" v-model="nombre" type="text" name="nombreDE" id="nombreDE" required >
                    </div>
                    <div class="group_frm">
                      <label class="Labels_Formulario" for="apellidosDE">APELLIDOS</label>
                      <input :disabled="loaded" class="Inputs_dobles" v-model="apellidos" type="text" name="apellidosDE" id="apellidosDE" required>
                    </div>
                </div>
                 <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="empresaDE">NOMBRE DE LA EMPRESA (OPCIONAL)</label>
                      <input :disabled="loaded" class="Input_Solo" v-model="empresa" type="text" name="empresaDE" id="empresaDE">
                 </div>
                 <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="pais_regionDE">PAÍS / REGIÓN</label>
                      <p class="Pais">{{pais_region}}</p>
                 </div>
                 <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="direccionDE">DIRECCIÓN DE LA CALLE</label>
                      <input :disabled="loaded" class="Input_Solo" v-model="direccion" type="text" name="direccionDE" id="direccionDE" required placeholder="Número de la casa y nombre de la calle">
                 </div>
                 <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="direccion2DE">COLONIA</label>
                      <input :disabled="loaded" class="Input_Solo" v-model="direccion2" type="text" name="direccion2DE" id="direccion2DE" >
                 </div>
                 <!--Apartamento, habitación, etc. (OPCIONAL) CLASS mtrow placeholder="Colonia" -->
                 <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="localidad_ciudadDE">LOCALIDAD / CIUDAD</label>
                      <input :disabled="loaded" class="Input_Solo" v-model="localidad_ciudad" type="text" name="localidad_ciudadDE" id="localidad_ciudadDE" required>
                 </div>

                 <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="regionDE">REGIÓN / PROVINCIA</label>
                      <select :disabled="loaded" class="Input_Solo" id="regionDE" name="regionDE" v-model="region"  >
                                <option  v-for="(region, key) in  regiones" v-bind:key="key" v-bind:value="region">
                                  {{region}} 
                                </option>
                       </select> 
                 </div>

                  <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="cp_locDE">CÓDIGO POSTAL</label>
                      <input :disabled="loaded" class="Input_CP" v-model="cp_loc" type="text" name="cp_locDE" id="cp_locDE" required>
                 </div>

                  <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="telefonoDE">TELÉFONO</label>
                      <input :disabled="loaded" class="Input_Solo" v-model="telefono" type="text" name="telefonoDE" id="telefonoDE" required  minlength="10"
                              maxlength="10"
                              size="10"
                              pattern="\d*">
                  </div>
                   <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="emailDE">DIRECCIÓN DE CORREO ELECTRÓNICO</label>
                      <input :disabled="loaded" class="Input_Solo" v-model="email" type="email" name="emailDE" id="emailDE" required>
                  </div>
                 <!--  <div class="mtrow df_group_frm ShowInputs">
                       <input :disabled="loaded" v-model="cuenta" type="checkbox" name="cuentaDE" id="cuentaDE" > <label class="Labels_Formulario" label for="cuentaDE">¿CREAR UNA CUENTA?</label>
                      
                  </div>
               <div :class="{hiddenSec : !cuenta }">
                   <div class="df_group_frm">
                      <div class="mtrow group_frm">
                        <label class="Labels_Formulario" for="passwordDE">CREAR UNA CONTRASEÑA PARA LA CUENTA</label>
                        <input :disabled="loaded" class="Inputs_dobles" v-model="password"   minlength="8" type="password" :placeholder="'Contraseña'" name="passwordDE" id="passwordDE" :required="cuenta" >
                    </div>
                    <div class="mtrow group_frm">
                        <label class="Labels_Formulario" for="password2DE">CONFIRMAR CONTRASEÑA</label>
                        <input :disabled="loaded" class="Inputs_dobles" v-model="password2"   minlength="8" type="password" :placeholder="'Confirmar Contraseña'" name="password2DE" id="password2DE" :required="cuenta">
                    </div>
                   </div>
               </div>
               -->
                  <div class="mtrow df_group_frm ShowInputs">
                       <input :disabled="loaded" v-model="facturar" type="checkbox" name="facturarDE" id="facturarDE" > <label class="Labels_Formulario" for="facturarDE">¿NECESITA FACTURAR?</label>
                       <!--<p>{{facturar}}</p>-->
                  </div>

                  <div :class="{hiddenSec : !facturar }">
                    <div class="mtrow group_frm">
                        <label class="Labels_Formulario" for="razon_socialDE">RAZÓN SOCIAL</label>
                        <input :disabled="loaded" class="Input_Solo" v-model="razon_social" type="text" name="razon_socialDE" id="razon_socialDE" :required="facturar">
                    </div>
                    
                     <div class="mtrow group_frm">
                        <label class="Labels_Formulario" for="rfcDE">RFC</label>
                        <input :disabled="loaded" class="Input_Solo" v-model="rfc" type="text" name="rfcDE" id="rfcDE" :required="facturar">
                    </div>
                    
                     <div class="mtrow group_frm">
                        <label class="Labels_Formulario" for="direccion_fiscalDE">DIRECCIÓN FISCAL</label>
                        <input :disabled="loaded" class="Input_Solo" v-model="direccion_fiscal" type="text" name="direccion_fiscalDE" id="direccion_fiscalDE" :required="facturar">
                     </div>
                    
                       <div class="mtrow group_frm">
                        <label class="Labels_Formulario" for="cp_facDE">CÓDIGO POSTAL</label>
                        <input :disabled="loaded" class="Input_Solo" v-model="cp_fac" type="text" name="cp_facDE" id="cp_facDE" :required="facturar">
                     </div>
                     <div class="mtrow group_frm">
                        <label class="Labels_Formulario" for="email_facDE">CORREO ELECTRÓNICO</label>
                        <input :disabled="loaded" class="Input_Solo" v-model="email_fac" type="email" name="email_facDE" id="email_facDE" :required="facturar">
                     </div>
                  </div>

                    <div class="mtrow group_frm">
                      <label class="Labels_Formulario" for="notasDE">NOTAS DEL PEDIDO</label>
                      <input :disabled="loaded" class="Input_Solo Special_Sice" v-model="notas" type="text" name="notasDE" id="notasDE" placeholder="Notas sobre tu pedido, por ejemplo, notas especiales para la entrega">
                   </div>

                  

                
              </div>


                   
            </div>
            <div class="cl2_shdls">
                   <div class="tupedido">
                       <h6 class="tupedido_title"> TU PEDIDO</h6>
                        <div class="productos_tupedido">
                            <p>PRODUCTO</p>
                            <p class="productos_tupedidoS">SUBTOTAL</p>
                            
                        </div>
                        <div class="items_tupedido">
                            <div v-for="(item, key) in cart.detalle"  :key="key">
                                <div class="itm_tpd">
                                  <div class="AllProducts">
                                      <p class="Producto_1">{{item.nombre}}</p>
                                      <p class="Producto_1desc">{{item.descripcion}}</p>
                                      <p>x{{item.cantidad}}</p>
                                  </div> 
                                  <p class="SubtotalOnly">${{item.subtotal}}</p>
                                </div>
                            </div>
                           
                            
                        </div>
                        <hr class="RedLine">
                        <div class="subtotal_tupedido">
                            <p>SUBTOTAL</p>
                            <p class="SubtotalOnl2"> ${{cart.subtotal}}</p>  
                        </div>
                        <hr class="RedLine">
                        <div class="envio_tupedido">
                            <p>ENVIO</p>
                            <div>
                                <!--checkbox -->
                                <p class="Precio_Fijo"> <span>${{cart.envio}}</span></p>
                                <!--<p>envio gratuito</p>-->
                            </div>
                        </div>
                        <hr class="RedLine">
                        <div class="subtotal_tupedido">
                            <p class="mg-rg">DESC.CUPÓN</p>
                            <p class="SubtotalOnl2"> - ${{cart.descupon}}</p>  
                        </div>
                     
                        <hr class="RedLine">
                        <div class="total_tupedido">
                            <p>TOTAL</p>
                            <p class="Total_TuPedido">${{cart.total}}</p>
                        </div>
                   </div>
                   
                  <!-- <div class="transferencia">
                      
                        <div class="radio_sec">
                       
                        <input type="radio" id="tran_banc" value='Transferencia' v-model="pago_con" :disabled="loaded">
                        <label for="tran_banc">Transferencia Bancaria Directa</label>
                      </div>
                      
                      
                       <p class="Text1">Realiza tu pago directamente en nuestra cuenta bancaria. Por favor, usa el número del pedido como referencia de pago. Tu pedido no se procesará hasta que se haya recibido el importe en nuestra cuenta.</p>
                   </div> -->
                   <div class="paypal-openpay">
                     <!-- <div class="radio_sec2">
                       
                        <input type="radio" id="paypal" value="PayPal" v-model="pago_con" :disabled="loaded">
                        <label for="paypal">PayPal</label>
                      </div> 
                      <div class="radio_sec2">
                       
                        <input type="radio" id="tarjeta" value="Tarjeta" v-model="pago_con" :disabled="loaded">
                        <label for="tarjeta">Pago con tarjeta de crédito o débito</label>
                      </div>-->
                   <div class="radio_sec2">
                       
                        <input type="radio" id="OpenPay" value="OpenPay" v-model="pago_con" :disabled="loaded">
                        <label for="OpenPay">Openpay</label>
                      </div> 
                   </div>
                   <div>
                       <p class="Text2">Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web y otros propósitos descritos en nuestra <span>política de privacidad</span>.</p>
                   </div>
                     <div class="radio_sec3">
                       
                        <input type="radio" id="term_cond" value="aceptados" v-model="terminos" :disabled="loaded">
                        <label class="Text_Terminos" for="term_cond"> HE LEÍDO Y ESTOY DE ACUERDO CON LOS <a href="/terminos-y-condiciones"><span>TÉRMINOS Y CONDICIONES</span></a> DE LA WEB *</label>
                      </div>
                  
                  <div>
                    <!--<p>{{pago_con}}</p>
                    <p> {{terminos}}</p>-->
                   
                  </div> 
                   <div v-if="cart.total < 10000">
                    
                       <button :disabled="loaded" :class="{hiddenSec : loaded}" class="Pedir" type="submit" >REALIZAR EL PEDIDO<img src="../../assets/arrow2.png"></button>
                   </div>
                   <div v-else>
                        <p class="cart_msg cart_error">LIMITE DE COMPRA SUPERADO, EL TOTAL DEBE SER MENOR A $10,000 MXN.</p>
                   </div>

<!--  :class="{disabled_btn_tarjeta : pago_con == 'PayPal', disabled_btn_paypal : pago_con == 'Tarjeta' }" -->
<div  class="metodos_pago">
     <div  id="paypal-button"> </div>
         </div>


         <div v-if="viewModal" class="modal_view_checkout">
      
        <div id="cnt-mdl-checkout">
           <img v-if="historyOptionPsl =='Default' || historyOptionPsl == 'OpenPay'" src="../../assets/eliminar_cupon.svg" class="close_modal" alt="close modal" @click="viewModal = false, loaded = false, setHistoryOptionPsl('Default')">
           <OpenPayForm /> 
         <!--  <p>PROXIMAMENTE...</p> -->
        </div>
    </div>



                   <div v-if="this.status !=''">
                      <div v-if="this.status =='success'">
                          <p class="cart_msg cart_success">{{this.msg}}</p>
                      </div>
                        <div v-if="this.status =='error'">
                          <p class="cart_msg cart_error">{{this.msg}}</p>
                      </div>
                  </div>
                    
            </div>

        </div>
          </form>
      

         
      
  
    </div>



    <Footer />

    </div>
</template>
<script>


// 

import { mapActions } from "vuex";
let urlpath = require('../../global/url');

import PayPal from  '../../components/PayPalComp.vue'
import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import AnuncioBar from "../../components/AnuncioBar";
import OpenPayForm from '../../components/pasarelas/openpay.vue'

import dayjs from "dayjs";
import 'dayjs/locale/es' // load on demand

export default {
  name: "shipmentDetails",
  components:{
    HeaderHome,
    Footer,
    PayPal,
    AnuncioBar,
    OpenPayForm
  },
  data() {
    return {
     
      status: "",
      msg: "",
      urlpath,


      //formulario
      nombre:'',
      apellidos:'',
      empresa:'',
      pais_region:'México',
      direccion:'', //calle nombre num casa
      direccion2:'', // opcional departamento etc.
      localidad_ciudad:'',
      region:'Sonora',
      cp_loc:'',
      telefono:'',
      email:'',

     

      cuenta:false, //crear cuenta.
      password:'',
      password2:'',


      facturar:false,
      razon_social:'',
      rfc:'',
      direccion_fiscal:'',
      cp_fac:'',
      email_fac:'',
      notas:'',

      pago_con:'sin_elejir',
      terminos:'sin aceptar',


      regiones:[
      "Aguascalientes" ,
      "Baja California" ,
      "Baja California Sur" ,
      "Campeche" ,
      "Chiapas" ,
      "Chihuahua" ,
      "Ciudad de México" ,
      "Coahuila" ,
      "Colima ",
      "Durango" ,
      "Estado de México" ,
      "Guanajuato" ,
      "Guerrero" ,
      "Hidalgo" ,
      "Jalisco" ,
      "Michoacán" ,
      "Morelos" ,
      "Nayarit" ,
      "Nuevo León" ,
      "Oaxaca" ,
      "Puebla" ,
      "Querétaro" ,
      "Quintana Roo" ,
      "San Luis Potosí" ,
      "Sinaloa" ,
      "Sonora" ,
      "Tabasco" ,
      "Tamaulipas" ,
      "Tlaxcala" ,
      "Veracruz" ,
      "Yucatán" ,
      "Zacatecas" ,
    ] ,

      //Paypal
      loaded: false,
      paidFor: false,
      shipmentData:false,

      viRegion:'Sonora',
      envioGratis:false,
      primeraCarga:false,


       viewModal: false,
      
    };
  },
  mounted: function() {

    //AUU4vNibTFvy-_iq2qjO_srvbp4vvzmyMH9Bznw7OM2x_2xYYl-pmkw85VZQB0JJ4eS7-y3ugLDLv9S6 pruebas
    //ATSi6n_wSY08EjzhkY1Iaga7Gbic24XGj6QvMxvnw070Mx859ZiQFK2c1AoGUmkczrZdWMexfL2pq2Wb
    let url =  "https://www.paypal.com/sdk/js?client-id=ATSi6n_wSY08EjzhkY1Iaga7Gbic24XGj6QvMxvnw070Mx859ZiQFK2c1AoGUmkczrZdWMexfL2pq2Wb&currency=MXN&locale=es_MX&components=buttons,funding-eligibility"
     const el = document.querySelector(`script[src="${url}"]`);
      if (!el) {
          const s = document.createElement('script');
          s.setAttribute('src', url); //s.onload = this.setLoaded;
          document.head.insertBefore(s, document.head.firstElementChild);
      }
 
  },
 /*watch:{
      region(value, old){
        if(value != old){
          this.envioRecalc(this.cart.envio)
         
        }
      },
       localidad_ciudad(value, old){
        if(value != old){
          this.envioRecalc(this.cart.envio)
         
        }
      }
  },*/


  created() {
      this.getCar()
     this.getAll('pedidos')
     

     let usertoken = localStorage.getItem('token')
     if(!this.user.sub || !usertoken){
      this.$router.push("/carrito").catch(err => {});
     }
    this.nombre = this.user.name
    this.apellidos =   this.user.lastname
    this.email = this.user.email
  },
async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
 
  computed:{
    url() {
      return this.$store.getters["main/baseURL"];
    },
    cart() {
     let cart = this.$store.getters["cart/data"];
    
     if(cart.subtotal){
        let ciudad = this.localidad_ciudad
        ciudad = ciudad.toLowerCase()
        ciudad = ciudad.trim()
       

       let  freeShipping = false
       let descupon =0
      
       for (const cupon of cart.cupon) {
          if(cupon.freeShipping =='SI'){
            freeShipping = true;
            break
          }
       }
       if(!isNaN(cart.descupon)){
          descupon = cart.descupon
       }


       if(freeShipping == false){
             if(this.region == 'Sonora'){
               cart.envio  = 99
               cart.total =   (cart.envio + cart.subtotal)  -descupon
               cart.total = Math.round(cart.total *100)/100;
             if(ciudad =='hermosillo'){
                 cart.envio = 0
                 cart.total =   (cart.envio + cart.subtotal)  -descupon
                 cart.total = Math.round(cart.total *100)/100;
             }  
            }else{
              cart.envio =150
              cart.total =  (cart.envio + cart.subtotal)  -descupon 
              cart.total = Math.round(cart.total *100)/100;
            }
       }else{
          
              cart.envio = 0
              cart.total =   (cart.envio + cart.subtotal)  -descupon
              cart.total = Math.round(cart.total *100)/100;
            
       }
     }
     
      
  
        

            


     return cart 
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    data() { 
           return   this.$store.getters["purchase/data"]; //'No se encontraron coincidencias'//
    }, 
          historyOptionPsl() {
        return this.$store.getters["pasarela/getHistoryOption"];
    },
   
  
  },
  methods: {
     ...mapActions('cart', ['getCar']),
     ...mapActions('purchase', ['getAll']),
     ...mapActions('pasarela', ['setHistoryOptionPsl']),
     ...mapActions('pasarela', ['setAddedPsl']),

     envioRecalc: function (envio){
    
       if(envio == undefined){
         return this.cart.envio
       }
        let ciudad = this.localidad_ciudad
        ciudad = ciudad.toLowerCase()
        ciudad = ciudad.trim()
       

       let  freeShipping = false
       let descupon =0
      
       for (const cupon of this.cart.cupon) {
          if(cupon.freeShipping =='SI'){
            freeShipping = true;
            break
          }
       }
       if(!isNaN(this.cart.descupon)){
          descupon = this.cart.descupon
       }


       if(freeShipping == false){
             if(this.region == 'Sonora'){
               this.cart.envio  = 99
               this.cart.total =   (this.cart.envio + this.cart.subtotal)  -descupon
               this.cart.total = Math.round(this.cart.total *100)/100;
             if(ciudad =='hermosillo'){
                 this.cart.envio = 0
                 this.cart.total =   (this.cart.envio + this.cart.subtotal)  -descupon
                 this.cart.total = Math.round(this.cart.total *100)/100;
             }  
            }else{
              this.cart.envio =150
              this.cart.total =  (this.cart.envio + this.cart.subtotal)  -descupon 
              this.cart.total = Math.round(this.cart.total *100)/100;
            }
       }else{
          
              this.cart.envio = 0
              this.cart.total =   (this.cart.envio + this.cart.subtotal)  -descupon
              this.cart.total = Math.round(this.cart.total *100)/100;
            
       }



        return this.cart.envio
     },
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    refresh: function () {
      setTimeout(() => this.$router.go());
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs()
      ,1000);
      setTimeout(() => this.refresh()
      ,500);
    },
     delStatus2: function () {
      setTimeout(() => this.delMsgs()
      ,2000);
      setTimeout(() => this.refresh()
      ,500);
    },
    delMsgs: function(){
       this.status=""
       this.msg=""
    },
    introducirCupon:function(){
        alert('CUPÓN')
    },
    obtenerUsuario: async function(){
      let token = await this.$store.dispatch("admin/getToken");
      this.$store.dispatch("admin/decode", token);
    },
    submit: async function(nombre,
                    apellidos,
                    empresa,
                    pais_region,
                    direccion,
                    direccion2,
                    localidad_ciudad,
                    region,
                    cp_loc,
                    telefono,
                    email,
                    cuenta,
                    password,
                    password2,
                    facturar,
                    razon_social,
                    rfc,
                    direccion_fiscal,
                    cp_fac,
                    email_fac,
                    notas,
                    pago_con, 
                    terminos){ 
   
        let carrito = this.cart           
         let result = await this.$store.dispatch("cart/validateCart",  this.cart);
        let user = localStorage.getItem('token')
        if(!user){
           this.status ='error'
            this.msg ='No has iniciado sesión'
           this.$router.push("/carrito").catch(err => {});
        }
        
          if(result.status =='success'){
               this.realizarPedido(nombre,
                    apellidos,
                    empresa,
                    pais_region,
                    direccion,
                    direccion2,
                    localidad_ciudad,
                    region,
                    cp_loc,
                    telefono,
                    email,
                    cuenta,
                    password,
                    password2,
                    facturar,
                    razon_social,
                    rfc,
                    direccion_fiscal,
                    cp_fac,
                    email_fac,
                    notas,
                    pago_con, 
                    terminos, carrito)
          }else if(result.message=='Tienes productos que han superdado el stock.'){
              this.status ='error'
              this.msg ='Tienes productos que han superdado el stock.  Producto: '+ result.result.producto.nombre + ' . Stock disponible: ' + result.result.stock +' . Actualiza la cantidad y vuelve a intentarlo.'
             this.$router.push("/carrito").catch(err => {});
          }else{
              localStorage.removeItem('kth')
              this.$router.push("/").catch(err => {});
          }       

    },
    realizarPedido:  function( 
                    nombre,
                    apellidos,
                    empresa,
                    pais_region,
                    direccion,
                    direccion2,
                    localidad_ciudad,
                    region,
                    cp_loc,
                    telefono,
                    email,
                    cuenta,
                    password,
                    password2,
                    facturar,
                    razon_social,
                    rfc,
                    direccion_fiscal,
                    cp_fac,
                    email_fac,
                    notas,
                    pago_con, 
                    terminos, carrito){

        let user = localStorage.getItem('token')
        if(!user){
           this.status ='error'
            this.msg ='No has iniciado sesión'
           this.wait()
        }


        this.getCar()
      this.status=''
      this.msg=''

       let validateC = this.validateCuenta(nombre, apellidos, email, telefono, password, password2) // campos requeridos para registrar una cuenta.
       let validateF = this.validateFacturacion(razon_social, rfc, direccion_fiscal, cp_fac, email_fac)
       let validateD = this.validateDetalles(nombre, apellidos, direccion, localidad_ciudad, region, cp_loc, telefono, email )

      let numero_pedido = ''
      let dia = dayjs().format('DD')
      let año = dayjs().format('YY')
      let mes = dayjs().format('MM')
     


        let pedidos =this.data
        if(pedidos == 'No se encontraron coincidencias'){
            numero_pedido= año+mes+dia+'1'
        }else{
            let lastpedido = pedidos[0].purchase_num
            lastpedido = parseInt(lastpedido)
            numero_pedido  = lastpedido+1
        }
    
        

      numero_pedido = String(numero_pedido)
      let fecha_descr = dayjs().locale('es').format('MMMM D, YYYY')
      let pedido = {
        "nombre": nombre,
        "apellidos": apellidos,
        "empresa": empresa,
        "pais_region": pais_region,
        "direccion": direccion,
        "direccion2": direccion2,
        "localidad_ciudad":localidad_ciudad,
        "region_provincia":region,
        "codigo_postal":cp_loc,
        "telefono": telefono,
        "email":email,
        "notas":notas,
        "metodo_pago": pago_con,
        "numero_pedido":numero_pedido,
        "fecha_descr": fecha_descr
      }


      let facturacion ={
        "facturar": facturar,
        "razon_social":razon_social,
        "rfc":rfc,
        "direccion_fiscal": direccion_fiscal,
        "codigo_postal": cp_fac,
        "email_fac": email_fac,
      }

      let registrarUsuario = {
           "registrar":cuenta,
           "nombre": nombre,
           "apellidos": apellidos,
           "telefono": telefono,
           "email":email,
           "password": password,
           "password2": password2
      }





      if(pago_con == 'sin_elejir'){
        this.status ='error'
        this.msg='No has elejido el metodo de pago.'
      }else if(terminos== 'sin aceptar'){

        this.status ='error'
        this.msg='No has aceptado los terminos y condiciones.'
      }else if(cuenta==true && validateC.valid==false ){
          this.status ='error'
          this.msg = validateC.message
      }else if(facturar==true && validateF.valid==false ){
          this.status ='error'
          this.msg = validateF.message
      }else if(validateD == false ){
          this.status ='error'
          this.msg = validateD.message
      }else{
        /* this.status ='success'
         this.msg='Todo correcto, ahora puedes pagar.'
         */
         let data = {
           "pedido":pedido,
           "facturacion": facturacion,
           "registrarUsuario": registrarUsuario,
           "cart": carrito
         }

         this.shipmentData = data
          //console.log(carrito)
         
         if(this.loaded == false){
                if(this.pago_con =='OpenPay'){
                   this.setAddedPsl(data)
                    this.setHistoryOptionPsl('OpenPay')
                    this.viewModal = true
                }else{
                  this.setLoaded(carrito, pedido, facturacion, registrarUsuario);
                  this.loaded = true;
                }  
        }
              

       

        
     
         
         
         
         
      }
    },
  




    





    //Metodos de validacion

 validateDetalles: function(nombre,apellidos, direccion, localidad_ciudad, region, cp_loc, telefono, email ){
    let valid = true;
    let message = '';
    
  if(nombre =='' || nombre == undefined || nombre== null){
      valid = false
      message ='Campo de nombre no es valido.'
    }
    
     if(apellidos =='' || apellidos == undefined || apellidos== null){
      valid = false
       message ='Campo de apellidos no es valido.'
    }


    if(telefono =='' || telefono == undefined || telefono== null){
      valid = false
       message ='Campo de teléfono no es valido.'
    }

    if(email =='' || email == undefined || email== null){
      valid = false
       message ='Campo de email no es valido.'
    }

     if(region =='' || region == undefined || region== null){
      valid = false
       message ='Campo de region no es valido.'
    }

     if(direccion =='' || direccion == undefined || direccion== null){
      valid = false
       message ='Campo de dirección no es valido.'
    }

     if(localidad_ciudad =='' || localidad_ciudad == undefined || localidad_ciudad== null){
      valid = false
       message ='Campo de localidad/ciudad no es valido.'
    }

     if(cp_loc =='' || cp_loc == undefined || cp_loc== null){
      valid = false
       message ='Campo de código postal no es valido.'
    }

 
    

    return {"valid":valid,"message": message}
  },


 validateFacturacion: function(razon_social, rfc, direccion_fiscal, cp_fac, email_fac){
    let valid = true;
    let message = ''
    if(razon_social =='' || razon_social == undefined || razon_social== null){
      valid = false
      message ='Campo de razón social no es valido.'
    }

     if(rfc =='' || rfc == undefined || rfc== null){
      valid = false
      message ='Campo de RFC no es valido.'
    }

     if(direccion_fiscal =='' || direccion_fiscal == undefined || direccion_fiscal== null){
      valid = false
      message ='Campo de dirección fiscal no es valido.'
    }

     if(cp_fac =='' || cp_fac == undefined || cp_fac== null){
      valid = false
      message ='Campo de código postal no es valido.'
    }

     if(email_fac =='' || email_fac == undefined || email_fac == null){
      valid = false
      message ='Campo de correo electrónico no es valido.'
    }
    
    

    return {"valid":valid,"message": message}
  },


  validateCuenta: function(nombre, apellidos, email, telefono, password, password2){
    let valid = true;
    let message = ''
    if(nombre =='' || nombre == undefined || nombre== null){
      valid = false
      message ='Campo de nombre no es valido.'
    }
    
     if(apellidos =='' || apellidos == undefined || apellidos== null){
      valid = false
       message ='Campo de apellidos no es valido.'
    }

    if(telefono =='' || telefono == undefined || telefono== null){
      valid = false
       message ='Campo de teléfono no es valido.'
    }

    if(email =='' || email == undefined || email== null){
      valid = false
       message ='Campo de email no es valido.'
    }

    if(password =='' || password == undefined || password== null){
      valid = false
       message ='Campo de contraseña no es valido.'
    }

    if(password2 =='' || password2 == undefined || password2== null){
      valid = false
       message ='Campo de confirmar contraseña no es valido.'
    }

    if(password  != password2){
       valid = false
       message ='Contraseñas no coinciden.'
    }

    

    return {"valid":valid,"message": message}
    },




    //Paypal
    setLoaded: function(cart, pedido, facturacion, registrarUsuario) {
   
   
     var FUNDING_SOURCE =  paypal.FUNDING.PAYPAL;
      if(pedido.metodo_pago == 'PayPal'){
          FUNDING_SOURCE =  paypal.FUNDING.PAYPAL;
      }else if(pedido.metodo_pago =='Tarjeta'){
          FUNDING_SOURCE =  paypal.FUNDING.CARD;
      }else{
          FUNDING_SOURCE =  paypal.FUNDING.PAYPAL
      }
    
        paypal.Buttons({
                  fundingSource:   FUNDING_SOURCE,
                  createOrder: (data, actions) => {
                    
                    return actions.order.create({
                      purchase_units: [
                        {
                          // description: 'Tu compra en la tienda de Cimarrones de Sonora.',
                          amount: {
                            currency_code: "MXN",
                            value: cart.total
                          }
                        }
                      ]
                    });

                  
                  },
                  onApprove: async (data, actions) => {
                    const order = await actions.order.capture();

                 
                    this.status='success'
                    this.msg ='Compra completada'
                    
                    if(order.status = 'COMPLETED'){

                       let COMPLETED = {
                          "pedido":pedido,
                          "facturacion": facturacion,
                          "registrarUsuario": registrarUsuario,
                          "cart": this.cart,
                          "order": {"order_id":order.id, "payer_id": order.payer.payer_id},
                        }

                        let result = await this.$store.dispatch("purchase/createPurchase", COMPLETED);
                      
                        
                        if(result.status == 'success'){
                              COMPLETED = JSON.stringify(COMPLETED)
                              localStorage.setItem('COMPLETED',COMPLETED)
                              localStorage.removeItem('kth')
                              this.$router.push("/detalle-compra").catch(err => {});
                        }else{

                          this.status = result.status
                          this.msg= result.message;
                        }
                        



                     

                          
                    }
                    
                  },
                  onError: err => {
                    //console.log(err);
                    this.status='error'
                    this.msg= 'Ha ocurrido un error al intentar procesar tu compra.'
                    
                  }
                })
                .render('#paypal-button');
      
    
     
    }
   
    
  },

};
</script>

<style scoped>

    .hiddenSec{
      display: none;
    }
    .mg-rg{
      margin-right: 11.3vw !important;
    }
/*
    .disabled_btn_paypal{
       background: red;
    }

.disabled_btn_tarjeta{
  background: violet;
}

    .disabled_btn_paypal .paypal-button-number-0 {
     display: none !important;

}

.disabled_btn_tarjeta   .paypal-button-number-1 {
     display: none !important;
}

*/
</style>
