
<template>
  <div>
  
    <div  id="paypal-button"> </div>
  </div>
</template>

<script>
// import image from "../assets/lamp.png"
export default {
  name: "PayPalComp",
  data: function() {
    return {
      loaded: false,
      paidFor: false,
      product: {
        price: 777.77,
        description: "leg lamp from that one movie",
        img: "./assets/lamp.jpg"
      }
    };
  },
  
  mounted: function() {


    let url =  "https://www.paypal.com/sdk/js?client-id=AVD2PTNOOj3K7yZ0buSwq0irNZnicqTQ8RdpALc5qxPHhP_Sb1wEtKnnspIabPJrqcm1FXQ34Sji4csN&currency=MXN"
     const el = document.querySelector(`script[src="${url}"]`);
      if (!el) {
          const s = document.createElement('script');
          s.setAttribute('src', url); s.onload = this.setLoaded;
          document.head.insertBefore(s, document.head.firstElementChild);
      }
 
  },
 
  methods: {
    setLoaded: function() {
      this.loaded = true;
      paypal.Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: "MXN",
                    value: this.product.price
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.data;
            this.paidFor = true;
            console.log(order);
          },
          onError: err => {
            console.log(err);
          }
        })
        .render('#paypal-button');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
