import { render, staticRenderFns } from "./shipmentDetails.vue?vue&type=template&id=b9dd7d62&scoped=true&"
import script from "./shipmentDetails.vue?vue&type=script&lang=js&"
export * from "./shipmentDetails.vue?vue&type=script&lang=js&"
import style0 from "./shipmentDetails.vue?vue&type=style&index=0&id=b9dd7d62&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9dd7d62",
  null
  
)

export default component.exports